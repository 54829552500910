import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { some } from "lodash";
import styled from "styled-components";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import DropdownCard from "../../../../../components/baseComponents/DropdownCard";
import BarLoader from "../../../../../components/baseComponents/BarLoader";
import ButtonToForm from "../../../../components/ButtonToForm";
import DepositTransactionTool from "./DepositTransactionTool";
import {
  getSecurityDepositChargeTransaction,
  getSecurityDepositPaymentTransaction,
  getRentableTrustDepositTransaction,
  createDepositChargeTransaction,
  createDepositPaymentTransaction,
  createRentableTrustBankDeposit,
} from "../../../../utils/rentManagerUtils";
import { formatDateValueAsLocale } from "../../../../../components/global/utils";
import { GenericObject } from "../../../../../components/global/ModelInterfaces";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import usePrepaymentFromParams from "../../../../utils/usePrepaymentFromParams";

const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledTitle = styled.span`
  text-decoration: underline;
`;

const DepositTransactionsSection = () => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { prepayment } = usePrepaymentFromParams();
  const [depositChargeTransaction, setDepositChargeTransaction] =
    useState<GenericObject | null>(null);
  const [depositPaymentTransaction, setDepositPaymentTransaction] =
    useState<GenericObject | null>(null);
  const [bankDepositIntoRentableTrust, setBankDepositIntoRentableTrust] =
    useState<GenericObject | null>(null);

  useEffect(() => {
    if (!depositChargeTransaction) {
      setLoading(true);
      const successCallback = (chargeTransaction: GenericObject) => {
        setLoading(false);
        setDepositChargeTransaction(chargeTransaction);
      };
      const failureCallback = (errorMessage: string) => {
        setError(errorMessage);
        setLoading(false);
      };
      getSecurityDepositChargeTransaction(
        prepaymentUUID,
        successCallback,
        failureCallback
      );
    }
  }, []);

  useEffect(() => {
    if (some(depositChargeTransaction) && !depositPaymentTransaction) {
      setLoading(true);
      const successCallback = (paymentTransaction: GenericObject) => {
        setLoading(false);

        setDepositPaymentTransaction(paymentTransaction);
      };
      const failureCallback = (errorMessage: string) => {
        setError(errorMessage);
        setLoading(false);
      };
      getSecurityDepositPaymentTransaction(
        prepaymentUUID,
        successCallback,
        failureCallback
      );
    }
  }, [depositChargeTransaction]);

  useEffect(() => {
    if (
      some(depositChargeTransaction) &&
      some(depositPaymentTransaction) &&
      depositPaymentTransaction &&
      !bankDepositIntoRentableTrust
    ) {
      setLoading(true);
      const successCallback = (depositTransaction: GenericObject) => {
        setLoading(false);
        setBankDepositIntoRentableTrust(depositTransaction);
      };
      const failureCallback = (errorMessage: string) => {
        setError(errorMessage);
        setLoading(false);
      };
      getRentableTrustDepositTransaction(
        prepaymentUUID,
        depositPaymentTransaction.id,
        successCallback,
        failureCallback
      );
    }
  }, [depositPaymentTransaction]);

  if (loading) {
    return (
      <Row justifyContent="center" mb={{ default: 5 }}>
        <BarLoader loadingText="Loading Rent Manager Transactions" />
      </Row>
    );
  }

  return (
    <DropdownCard title={"Deposit Payment Transactions"} initiallyOpen={false}>
      <Row>
        {error ? (
          <>
            <ErrorMessage>{error}</ErrorMessage>
          </>
        ) : (
          <>
            <Div width={{ default: 4 / 12 }}>
              {some(depositChargeTransaction) && depositChargeTransaction ? (
                <>
                  <StyledTitle>
                    Security Deposit Charge Transaction Info:
                  </StyledTitle>
                  <Row>
                    <StyledLabel>Charge ID:</StyledLabel>
                    {depositChargeTransaction.id}
                  </Row>
                  <Row>
                    <StyledLabel>Charge Date:</StyledLabel>
                    {formatDateValueAsLocale(
                      depositChargeTransaction.transaction_date
                    )}
                  </Row>
                  <Row>
                    <StyledLabel>Charge Unit ID:</StyledLabel>
                    {depositChargeTransaction.unit_id ? (
                      <>{depositChargeTransaction.unit_id}</>
                    ) : (
                      <span>This charge does not have an assigned UnitID</span>
                    )}
                    {depositChargeTransaction.unit_id !=
                      prepayment.integration_info.details
                        .rent_manager_unit_id && (
                      <ErrorMessage>
                        The UnitID for this charge does not match the UnitID for
                        our prepayment. Please reach out to the Property Manager
                        to fix this.
                      </ErrorMessage>
                    )}
                  </Row>
                </>
              ) : (
                <>
                  <Div>
                    This deposit has been funded in Rentable, but does not have
                    the corresponding Rent Manager Security Deposit charge. You
                    can use the tool below to create it.
                  </Div>
                  <Div mt={{ default: 3 }}>
                    <ButtonToForm
                      formTitle="Create Security Deposit Charge Transaction"
                      buttonText="Create Charge"
                    >
                      <DepositTransactionTool
                        createRentManagerDepositTransaction={
                          createDepositChargeTransaction
                        }
                        onSuccess={(chargeTransaction: GenericObject) => {
                          setDepositChargeTransaction(chargeTransaction);
                        }}
                      />
                    </ButtonToForm>
                  </Div>
                </>
              )}
            </Div>
            <Div width={{ default: 4 / 12 }}>
              {some(depositPaymentTransaction) && depositPaymentTransaction ? (
                <>
                  <StyledTitle>
                    Security Deposit Payment Transaction Info:
                  </StyledTitle>
                  <Row>
                    <StyledLabel>Payment ID:</StyledLabel>
                    {depositPaymentTransaction.id}
                  </Row>
                  <Row>
                    <StyledLabel>Payment Date:</StyledLabel>
                    {formatDateValueAsLocale(
                      depositPaymentTransaction.transaction_date
                    )}
                  </Row>
                </>
              ) : (
                <>
                  <Div>
                    This deposit has been funded in Rentable, but does not have
                    the corresponding RM Security Deposit Payment transaction.
                    You can use the tool below to create it.
                  </Div>
                  <Div mt={{ default: 3 }}>
                    <ButtonToForm
                      formTitle="Create Security Deposit Payment Transaction"
                      buttonText="Create Payment"
                    >
                      <DepositTransactionTool
                        createRentManagerDepositTransaction={
                          createDepositPaymentTransaction
                        }
                        onSuccess={(paymentTransaction: GenericObject) => {
                          setDepositPaymentTransaction(paymentTransaction);
                        }}
                      />
                    </ButtonToForm>
                  </Div>
                </>
              )}
            </Div>
            <Div width={{ default: 4 / 12 }}>
              {some(bankDepositIntoRentableTrust) &&
              bankDepositIntoRentableTrust ? (
                <>
                  <StyledTitle>Bank Deposit into Rentable Trust:</StyledTitle>
                  <Row>
                    <StyledLabel>Bank Deposit ID:</StyledLabel>
                    {bankDepositIntoRentableTrust.id}
                  </Row>
                  <Row>
                    <StyledLabel>Bank Deposit Date:</StyledLabel>
                    {formatDateValueAsLocale(
                      bankDepositIntoRentableTrust.transaction_date
                    )}
                  </Row>
                </>
              ) : (
                <>
                  <Div>
                    This deposit has been funded in Rentable, but does not have
                    the corresponding Bank Deposit into Rentable Trust in Rent
                    Manager. You can use the tool below to create it.
                  </Div>
                  <Div mt={{ default: 3 }}>
                    <ButtonToForm
                      formTitle="Create Bank Deposit into Rentable Trust"
                      buttonText="Create Bank Deposit"
                    >
                      <DepositTransactionTool
                        createRentManagerDepositTransaction={
                          createRentableTrustBankDeposit
                        }
                        onSuccess={(bankDepositTransaction: GenericObject) => {
                          setBankDepositIntoRentableTrust(
                            bankDepositTransaction
                          );
                        }}
                      />
                    </ButtonToForm>
                  </Div>
                </>
              )}
            </Div>
          </>
        )}
      </Row>
    </DropdownCard>
  );
};

export default DepositTransactionsSection;
